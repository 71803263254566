import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import {
  Layout,
  Media,
  Pagination,
  PaginationLink,
  SEO,
  Typography,
} from '../components';
import i18n from '../i18n';

class ProjectTemplate extends React.Component {
  /**
   * Render the project's content
   * @return {*}
   */
  renderContent() {
    const {
      data: {
        projectsJson: {
          content: CONTENT,
        },
      },
    } = this.props;

    return <Typography content={CONTENT} />;
  }

  /**
   * Render the project's content
   * @return {*}
   */
  renderMedia() {
    const {
      data: {
        projectsJson: {
          images: {
            desktop: DESKTOP_IMAGE,
            mobile: MOBILE_IMAGE,
          },
        },
      },
    } = this.props;

    return (
      <Media
        mobile={MOBILE_IMAGE}
        desktop={DESKTOP_IMAGE}
      />
    );
  }

  /**
   * Render the pagination
   * @return {*}
   */
  renderPagination() {
    const {
      pageContext: {
        pagination: {
          prev: PREV_NODE,
          next: NEXT_NODE,
        },
      },
    } = this.props;

    return (
      <Pagination>
        <PaginationLink
          {...PREV_NODE}
          heading={i18n.pagination.prev}
        />
        <PaginationLink
          {...NEXT_NODE}
          heading={i18n.pagination.next}
        />
      </Pagination>
    );
  }

  render() {
    const {
      data: {
        location: LOCATION,
        projectsJson: {
          title: TITLE,
        },
      },
    } = this.props;

    return (
      <Layout
        location={LOCATION}
        title={TITLE}
      >
        <SEO title={TITLE} />
        <section className="u-mt--lg">
          <div className="l-container">
            <h1 className="c-heading c-heading--h2">{TITLE}</h1>
            <div className="u-mt--sm">
              {this.renderContent()}
            </div>
            <div className="u-mt--md">
              {this.renderMedia()}
              {this.renderPagination()}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

ProjectTemplate.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.shape().isRequired,
};

export const pageQuery = graphql`
  query ($slug: String!) {
    projectsJson(slug: { eq: $slug }) {
      content
      id
      images {
        desktop
        mobile
      }
      navigation {
        previous
        next
      }
      slug
      title
    }
  }
`;

export default ProjectTemplate;
